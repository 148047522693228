/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_appsync_graphqlEndpoint": "https://odgg2jfcrbczxatupbolhbskki.appsync-api.ap-southeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-bmwncdurgngt5ef5xgew3i2ryy",
    "aws_cognito_identity_pool_id": "ap-southeast-1:6d433d46-c015-4034-93d7-6d499e5ec33d",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_vH42ucFqW",
    "aws_user_pools_web_client_id": "3kqn5gbi1i8i6b3g2q0t4bv54q",
    "oauth": {
        "domain": "pretepop-master.auth.ap-southeast-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://a.pretepop.com/",
        "redirectSignOut": "https://a.pretepop.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_user_files_s3_bucket": "storage124810-master",
    "aws_user_files_s3_bucket_region": "ap-southeast-1"
};


export default awsmobile;
